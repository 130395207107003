import { Typography, Box, Stack, Grid } from '@mui/material';
import React from 'react';
import ProjectCard from './project-card';
import { useMediaQuery, useTheme } from '@mui/material';

const Projects = () => {
  
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const projectData = [
    {
      title: 'Data Analysis and Ledger Tools',
      desc: "Discover the potential of data with our specialized tools. Whether it's the Crypto-Ledger Analyzer for blockchain insights or the Mass Data Analyzer and Profit & Loss Account for financial clarity, our solutions helps you make informed decisions."
    },
    {
      title: 'AI and Image Recognition Solutions',
      desc: 'Engage with AI-driven innovation through our image recognition solutions. The AI Number Plate Detector and AI Text Reader provide streamlined automation and efficiency, altering the manner in which you engage with visual data.'
    }
  ];
  return (
    <Box id="projects" sx={{ color: '#000', paddingTop: { xs: '1rem', md: '2.5rem' } }}>
      <Stack spacing={2}>
        {/* Title */}
        <Typography
          sx={{
            color: 'primary.main',
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: '600'
          }}
        >
          PROJECTS
        </Typography>
        {/* Sub title */}
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '35px',
            fontWeight: '800'
          }}
        >
          Explore Our Work
        </Typography>
        <Grid
          container
          className="center"
          columnSpacing={{ md: 2 }}
          rowSpacing={{ xs: 2, md: 0 }}
          sx={{ paddingTop: '2rem' }}
        >
          {projectData.map((project, index) => (
            <Grid
              key={index}
              item
              xs={12}
              md={6}
              className={isMediumScreen ? 'center' : ''}
              sx={{
                display: 'flex',
                flexDirection: index === 0 ? 'row-reverse' : 'row'
              }}
            >
              <Box
                sx={{
                  height: { md: '450px', xs: '500px' },
                  width: { md: '375px', xs: '275px' },
                  backgroundColor: '#E7E9EB90',

                  transitionDuration: '200ms',

                  borderRadius: '15px',
                  '&:hover': {
                    backgroundColor: '#fafbfc'
                  }
                }}
              >
                <ProjectCard cardNum={index} cardData={projectData[index]} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default Projects;
