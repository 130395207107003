import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import retcorLogo from '../assets/logo/retcor-logo.png';
import retcorSmallLogo from '../assets/logo/retcor-logo-small.png';
import { Link, useNavigate } from 'react-router-dom';

const drawerWidth = 240;
const navItems = [
  {
    title: 'About Us',
    path: '/#about-us'
  },
  {
    title: 'Projects',
    path: '/#projects'
  },
  {
    title: 'Support',
    path: '/support'
  }
];

const Header = (props) => {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', color: 'white' }}>
      <IconButton
        onClick={() => navigate('/')}
        sx={{
          flexGrow: 1,
          textAlign: 'left',
          marginTop: '12px',
          '&:hover': {
            background: 'transparent'
          }
        }}
      >
        <img src={retcorSmallLogo}  alt="retcor small logo"></img>
      </IconButton>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <Link to={item.path}>
              <Button
                sx={{
                  color: 'black',
                  textTransform: 'none',
                  fontSize: '0.9rem',
                  fontWeight: '400',
                  paddingLeft: '2rem'
                }}
              >
                {item.title}
              </Button>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', position: 'relative', zIndex: '10' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          background: 'white',

          color: 'black',
          position: 'fixed',
          boxShadow: 'none'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            className="center"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: 'none' },
              '&:hover': {
                background: 'transparent'
              }
            }}
          >
            <MenuIcon />
            <img
              src={retcorSmallLogo}
              alt="retcor logo"
              style={{
                paddingLeft: '20px',
                //marginTop: '10px'
              }}
            ></img>
          </IconButton>
          <Stack direction={'row'} spacing={0} sx={{ flexGrow: 1 }}>
            <IconButton
              onClick={() => navigate('/')}
              sx={{
                display: {
                  xs: 'none',
                  sm: 'block'
                },
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                height: { md: '45px' },
                '&:hover': {
                  background: 'transparent'
                }
              }}
            >
              <img src={retcorLogo} alt="retcor logo" height={36} width="100%"></img>
            </IconButton>
          </Stack>
          <Stack
            direction="row-reverse"
            spacing={4}
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
                width: '75%'
              }
            }}
          >
            <Link to="/support">
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  fontSize: '1rem',
                  textAlign: 'center',
                  background: (theme) => theme.palette.primary.main
                }}
              >
                Contact
              </Button>
            </Link>

            <Link to="/#projects">
              <Button
                onClick={() => navigate('/#projects')}
                sx={{
                  color: 'black',
                  textTransform: 'none',
                  fontSize: '1rem',
                  background: 'transparent',
                  border: 'none',
                  boxShadow: 'none'
                }}
              >
                Projects
              </Button>
            </Link>
            <Link to="/#about-us">
              <Button
                onClick={() => navigate('/')}
                sx={{
                  color: 'black',
                  fontSize: '1rem',
                  textTransform: 'none'
                }}
              >
                About Us
              </Button>
            </Link>
          </Stack>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: {
              xs: 'block',
              sm: 'none'
            },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default Header;
