import React, { useEffect } from 'react';

const CookiePolicy = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.iubenda.com/iubenda.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <React.Fragment>
      <a
        href="https://www.iubenda.com/privacy-policy/16278720/cookie-policy"
        title="Cookie Policy"
        className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe policy"
      >
        Cookie Policy
      </a>
    </React.Fragment>
  );
};

export default CookiePolicy;
