import { Grid, Box } from '@mui/material';
import React from 'react';
import cryptoLedger from '../assets/screenprints/jasperreports_cryptotax_report1-private.png';
import cryptoLedger2 from '../assets/screenprints/jasperreports_cryptotax_report2-private.png';
import massDataAnalyzer from '../assets/screenprints/screenshot1.png';
import profitLoss1 from '../assets/screenprints/screenshot5.png';
import profitLoss2 from '../assets/screenprints/screenshot6.png';
import profitLoss3 from '../assets/screenprints/screenshot7.png';
import charts from '../assets/screenprints/screenshot2.png';

import TitleComponent from '../components/projects/title-component';
import SubProjectContent from '../components/projects/sub-project-content';
import SubProjectStepper from '../components/projects/sub-project-stepper';
import SubProjectImage from '../components/projects/sub-project-image';

const DataAnalysis = () => {
  const titleInfo = {
    title: 'Data Analysis and Ledger Tools',
    description:
      "Discover the potential of data with our specialized tools. Whether it's the Crypto-Ledger Analyzer for blockchain insights or the Mass Data Analyzer and Profit and Loss Account for financial clarity, our range of solutions helps you make informed decisions."
  };

  const subProjects = [
    {
      title: 'Crypto Ledger Analyzer',
      content: [
        'Calculate profit with any basecurrency like BTC (XXBT), ETH, EUR, USD, INR',
        'Calculate Profit for two account types and any country: private users and companies (e.g. GmbH, Limited).',
        'Taxable profit calculation is supported for these countries: DE, US, IN. Respect long-term capital-gains or loss specific to countries and account type.',
        'Apply taxing for staked assets (e.g. ADA, DASH, DOT).',
        'Calculate tax depending on U.S. state or country.',
        'Pivottable/Grouping and Drillthrough analysis from any information to the underlying transaction, order or ledger entry.'
      ],
      imageDetails: [
        {
          src: cryptoLedger,
          alt: 'crypto ledger with qr'
        },
        {
          src: cryptoLedger2,
          alt: 'crypto ledger excel'
        }
      ]
    },
    {
      title: 'Mass Data Analyzer',
      content: [
        'Powerful mass data analysis capabilities.',
        'Explore wasted resources from current and past orders spanning over two decades.',
        'Analyze machine downtimes, excessive material usage, and order cancellations.',
        'Categorized by parameters like year, week, day, employee, and weather conditions.',
        'Dynamic pivot tables with pre-aggregated data for real-time manipulation and visualization.'
      ],
      imageDetails: [
        {
          src: massDataAnalyzer,
          alt: 'mass data analyzer'
        }
      ]
    },

    {
      title: 'Profit and Loss Account',
      content: [
        'Profit and Loss Account feature tailored to meet your needs.',
        'Determine current profit and loss accounts according to the German Commercial Code (HGB).',
        'Various calculation options based on customer or company data models.',
        'Synchronous display across text, table, pivot, and report views.',
        'Analyze and edit P&L figures for precise financial management.'
      ],
      imageDetails: [
        {
          src: profitLoss1,
          alt: 'Bericht'
        },
        {
          src: profitLoss2,
          alt: 'Tabellenansicht'
        },
        {
          src: profitLoss3,
          alt: 'Textansicht'
        }
      ]
    },
    {
      title: 'Charts and Trend Forecast',
      content: [
        'Real-time updates and prompt display of business figures through dynamic charts and pie charts.',
        'Ability to drill down from aggregated data to specific orders, offers, and components for deeper insights.',
        'Clickable elements in charts reveal associated aggregated elements in a table format.',
        'Trend analysis to forecast future processing speeds for informed decision-making.'
      ],
      imageDetails: [
        {
          src: charts,
          alt: 'charts and trends'
        }
      ]
    }
  ];

  return (
    <Box
      sx={{
        paddingY: { md: '4rem', xs: '2rem' },
        marginTop: { md: '64px', xs: '56px' }
      }}
    >
      <Box
        sx={{
          paddingX: { xl: '8rem', md: '4rem', xs: '2rem' },
          '& > *': {
            paddingY: '1rem !Important'
          }
        }}
      >
        <TitleComponent titleInfo={titleInfo} />
        <Grid
          container
          sx={{
            '& > *': {
              paddingX: '1rem !Important'
            }
          }}
          rowSpacing={2}
        >
          <Grid item xs={12} lg={6}>
            <SubProjectContent projectDetails={subProjects[0]} />
          </Grid>
          <Grid className="center" item xs={12} lg={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <SubProjectStepper steps={subProjects[0].imageDetails.length} imageDetails={subProjects[0].imageDetails} />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          direction="row-reverse"
          sx={{
            '& > *': {
              paddingX: '1rem !Important'
            }
          }}
        >
          <Grid item xs={12} lg={6}>
            <SubProjectContent projectDetails={subProjects[1]} />
          </Grid>
          <Grid item xs={12} lg={6} className="center">
            <SubProjectImage imageDetails={subProjects[1].imageDetails} />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          sx={{
            '& > *': {
              paddingX: '1rem !Important'
            }
          }}
        >
          <Grid item xs={12} lg={6}>
            <SubProjectContent projectDetails={subProjects[2]} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              flexDirection: 'column'
            }}
            className="center"
          >
            <SubProjectStepper steps={subProjects[2].imageDetails.length} imageDetails={subProjects[2].imageDetails} />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          direction="row-reverse"
          sx={{
            '& > *': {
              paddingX: '1rem !Important'
            }
          }}
        >
          <Grid item xs={12} lg={6}>
            <SubProjectContent projectDetails={subProjects[3]} />
          </Grid>
          <Grid item xs={12} lg={6} className="center">
            <SubProjectImage imageDetails={subProjects[3].imageDetails} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DataAnalysis;
