import ProjectTabs from './views/projects-tabs';
import Impressum from './views/impressum';
import LandingPage from './views/landing-page';
import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './app.css';
import Footer from './components/footer';
import Header from './components/app-bar';
import Support from './views/support';
import ScrollToAnchor from './components/anchor';
import { responsiveFontSizes } from '@mui/material/styles';
import DataAnalysis from './views/data-analysis';
import AiRecognition from './views/ai-recognition';

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: ' Inter, Sans-serif'
    },
    palette: {
      primary: {
        main: '#e95420'
      }
    }
  });

  return (
    <>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <Header />
        <ScrollToAnchor />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="projects/*" element={<ProjectTabs />}>
            <Route path="dataanalysis" element={<DataAnalysis />} />
            <Route path="airecognition" element={<AiRecognition />} />
          </Route>
          <Route path="impressum" element={<Impressum />} />
          <Route path="support" element={<Support />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default App;
