import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import landing from '../assets/landingsvg.svg';
import { Link } from 'react-router-dom';


const CoverPage = () => {
  return (
    <>
      
      <Box
        sx={{
          height: {
            md: 'calc(100vh - 64px)',
            xs: 'calc(100vh - 56px)'
          },

          maxHeight: '1146px',
          width: '100%',
          display: 'flex',
          color: '#000',
          background: `url(${landing})`,
          marginTop: { md: '64px', xs: '56px' },
          paddingX: {
            md: '4rem',
            xs: '40px',
            lg: '8rem'
          },
          alignItems: 'center',
          backgroundRepeat: 'repeat'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Stack
            sx={{
              position: 'relative',
              maxWidth: '600px',
              color: '#fff'
            }}
            spacing={2}
          >
            <Typography variant="h4"> Welcome to retcor&#xae; GmbH</Typography>
            <Typography variant="h3" sx={{ lineHeight: '1' }}>
              {' '}
              software and hardware engineering services{' '}
            </Typography>
            <Typography variant="h6" sx={{ width: '80%' }}>
              {' '}
              Individual software and hardware development from a single source{' '}
            </Typography>
            <Typography>
              <Link to="/#about-us">
                <Button
                  sx={{
                    background: 'inherit',
                    border: '1px solid #fff',
                    color: '#fff'
                  }}
                >
                  <Typography
                    sx={{
                      paddingX: '8px',
                      fontSize: '13px'
                    }}
                  >
                    {' '}
                    KNOW MORE
                  </Typography>
                </Button>{' '}
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default CoverPage;
