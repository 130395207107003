import { Stack, Box } from '@mui/material';
import React from 'react';
import CoverPage from '../components/cover-page';
import Testimonials from '../components/testimonials';
import AboutUs from '../components/about-us';
import Projects from '../components/projects';

const LandingPage = () => {
  return (
    <Box>
      <Stack sx={{ padding: '0px' }} spacing={0}>
        <CoverPage />

        <Stack
          sx={{
            paddingX: { md: '8rem', xs: '2rem' }
          }}
          spacing={4}
        >
          <AboutUs />
          <Projects />
          <Testimonials />
        </Stack>
      </Stack>
    </Box>
  );
};

export default LandingPage;
