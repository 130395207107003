import React, { useEffect } from 'react';
import { Box } from '@mui/material';
const Support = () => {
  useEffect(() => {
    const formContainer = document.getElementById('formContainer');
    if (document.getElementById('be1824cc-4a71-4cfe-8f50-7f348688a292')) {
      return;
    }
    const script = document.createElement('script');
    script.async = true;
    script.id = 'be1824cc-4a71-4cfe-8f50-7f348688a292';
    script.setAttribute('data-yt-url', 'https://support.retcor.net');
    script.src = 'https://support.retcor.net/static/simplified/form/form-entry.js';
    script.setAttribute('data-theme', 'light');
    script.setAttribute('data-lang', 'en');

    if (formContainer) {
      formContainer.appendChild(script);

      window.addEventListener('beforeunload', function () {
        formContainer.removeChild(script);
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Box
        id="support"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '64px',
          display: 'flex'
        }}
      >
        <div id="formContainer"></div>
      </Box>
    </React.Fragment>
  );
};

export default Support;
