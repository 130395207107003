import { Grid, Typography } from '@mui/material';
import React from 'react';

const TitleComponent = ({ titleInfo }) => {
  return (
    <Grid container sx={{ paddingY: { md: '4rem', xs: '2rem' }, paddingX: '1rem' }}>
      <Grid item xs={12} lg={6} sx={{ marginBottom: '1rem' }}>
        <Typography
          variant="h4"
          sx={{
            paddingRight: { lg: '12rem' },
            paddingLeft: { lg: '1rem' },
            color: 'primary.main',
            fontWeight: '700',
            fontSize: { lg: '2rem !important' },
            lineHeight: 1.5
          }}
        >
          {titleInfo.title}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} className="center">
        <Typography
          paragraph
          sx={{
            fontSize: '15px',
            lineHeight: '1.75'
          }}
        >
          {titleInfo.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TitleComponent;
