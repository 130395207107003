import {  Grid,  Box } from '@mui/material';
import React from 'react';
import numberPlate from '../assets/screenprints/test_image3_pred.jpg';
import bill from '../assets/screenprints/marked_bill.png';
import billExcel from '../assets/screenprints/bill_excel.png';
import TitleComponent from '../components/projects/title-component';
import SubProjectContent from '../components/projects/sub-project-content';
import SubProjectImage from '../components/projects/sub-project-image';
import SubProjectStepper from '../components/projects/sub-project-stepper';

const AiRecognition = () => {
  const titleInfo = {
    title: 'AI and Image Recognition Solutions',
    description:
      'Engage with AI-driven innovation through our image recognitionsolutions. The AI Number Plate Detector and AI Text Reader provide streamlined automation and efficiency, altering the manner in which you engage with visual data.'
  };

  const subProjects = [
    {
      title: 'Number Plate Detector',
      content: [
        'Specific applications include recognizing license plates for speeding violations, monitoring public spaces, and access control.',
        'Recognized data can be accessed by other applications for further processing.',
        'License plate recognition can be used for privacy applications by implementing data protection measures.'
      ],

      imageDetails: [
        {
          src: numberPlate,
          alt: 'Number plate'
        }
      ]
    },
    {
      title: 'Text Reader',
      content: [
        'Manual data entry from bills obtained from supermarkets or invoices from online services has various day-to-day applications in data analytics.',
        'The text extraction service utilizes the latest AI algorithms to extract text from hard copies of documents.',
        'Supported business documents for data entry include Cheque, passport, invoice, bank statement, and receipt.',
        'Extracted information from receipts, for example, can be placed in a file format for further use.',
        'Processed further in our Ledger Analysis Tool.'
      ],
      imageDetails: [
        {
          src: bill,
          alt: 'Bill'
        },
        {
          src: billExcel,
          alt: 'Bill Excel'
        }
      ]
    }
  ];
  return (
    <Box
      sx={{
        paddingY: { md: '4rem', xs: '2rem' },
        marginTop: { md: '64px', xs: '56px' }
      }}
    >
      <Box
        sx={{
          paddingX: { xl: '8rem', md: '4rem', xs: '2rem' },
          '& > *': {
            paddingY: '1rem !Important'
          }
        }}
      >
        <TitleComponent titleInfo={titleInfo} />
        <Grid
          container
          rowSpacing={2}
          sx={{
            '& > *': {
              paddingX: '1rem !Important'
            }
          }}
        >
          <Grid item xs={12} lg={6}>
            <SubProjectContent projectDetails={subProjects[0]} />
          </Grid>
          <Grid item xs={12} lg={6} className="center">
            <SubProjectImage imageDetails={subProjects[0].imageDetails} />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          direction="row-reverse"
          sx={{
            '& > *': {
              paddingX: '1rem !Important'
            }
          }}
        >
          <Grid item xs={12} lg={6}>
            <SubProjectContent projectDetails={subProjects[1]} />
          </Grid>
          <Grid
            item
            className="center"
            xs={12}
            lg={6}
            sx={{
              flexDirection: 'column'
            }}
          >
            <SubProjectStepper steps={subProjects[1].imageDetails.length} imageDetails={subProjects[1].imageDetails} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AiRecognition;
