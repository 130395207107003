import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box } from '@mui/material';

const ProjectCard = ({ cardNum = 0, cardData }) => {
  const theme = useTheme();
  
  const navigate = useNavigate();
  const handleClick = (tabIndex) => {
   
    const url = tabIndex === 0 ? 'dataanalysis' : 'airecognition';
    navigate(`/#projects`);
    navigate(`/projects/${url}`);
  };

  return (
    <Box
      sx={{
        paddingTop: '3rem',
        paddingX: { md: '2.5rem', xs: '1.5rem' },
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          marginBottom: '2rem !Important'
        }
      }}
    >
      <Typography variant="h6">{cardData.title}</Typography>
      <Typography
        paragraph
        gutterBottom
        sx={{
          fontWeight: '300',
          fontSize: '14px',
          lineHeight: '1.75',
          flexGrow: 1
        }}
      >
        {cardData.desc}
      </Typography>
      <Typography variant="body2" sx={{ paddingBottom: { md: '1.5rem', xs: '1rem' } }}>
        <Button
          onClick={() => handleClick(cardNum)}
          variant="text"
          endIcon={<ArrowRightIcon color="primary" sx={{ fontSize: '32px !important' }} />}
          size="medium"
          sx={{
            textTransform: 'none',
            color: '#000',
            '&:hover': {
              background: 'transparent',
              color: (theme) => theme.palette.primary.main
            }
          }}
        >
          Know More
        </Button>
      </Typography>
    </Box>
  );
};

export default ProjectCard;
