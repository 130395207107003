import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        const element = document.getElementById(lastHash.current);
        if (element) {
          const desiredScrollY =
            element.getBoundingClientRect().top + window.scrollY - 64;
          window.scrollTo({ top: desiredScrollY, behavior: 'smooth' });
          lastHash.current = '';
        }
      }, 100);
    } else if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);
  return null;
}

export default ScrollToAnchor;
