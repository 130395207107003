import { Typography, Box, useTheme } from '@mui/material';
import React from 'react';

const AboutUs = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        id="about-us"
        sx={{
          paddingX: { xl: '8rem', md: '4rem', xs: '2rem' },
          paddingTop: { md: '2.5rem', xs: '1rem' }
        }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            paddingTop: '40px',
            textAlign: 'center',
            paddingBottom: '50px',
            fontSize: '1.5rem',
            fontWeight: '600'
          }}
        >
          ABOUT US
        </Typography>
        <Typography
          paragraph
          sx={{
            fontWeight: '400',
            fontSize: '1rem',
            lineHeight: '1.75',
            textAlign: 'center'
          }}
        >
          retcor GmbH, based in Bielefeld, Germany, offers software, hardware, and consulting services. Our expertise
          lies in process optimization, data analysis, software engineering, automation, and robotics. Our team develops
          tailored solutions to support your business growth.
        </Typography>
      </Box>
    </>
  );
};

export default AboutUs;
