import { Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import contact from '../assets/contact-details.png';
import { Link } from 'react-router-dom';
import PrivacyPolicy from './privacy-policy';
import CookiePolicy from './cookie-policy';
import TermsAndConditions from './terms-conditions';

const Footer = () => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <footer
        style={{
          paddingBottom: '10px',
          paddingTop: '30px',
          backgroundColor: '#28293E'
        }}
      >
        <Grid
          container
          sx={{
            paddingX: {
              md: '75px',
              xs: '30px'
            },
            paddingTop: '20px'
          }}
        >
          <Grid item xs={12} sm={6} sx={{ paddingBottom: '20px' }}>
            <Stack
              spacing={{
                md: 1,
                xs: 1
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: '600',
                  paddingBottom: '6px'
                }}
                color={'primary.main'}
              >
                {' '}
                Contact
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: '400',
                  paddingBottom: '6px'
                }}
                color="#FFF"
              >
                {' '}
                retcor GmbH
              </Typography>

              <Stack direction="row" alignItems="center" gap={1}>
                <EmailIcon
                  sx={{
                    fontSize: '1rem',
                    color: '#FFF'
                  }}
                />

                <img src={contact} alt="contact" width="125"></img>
              </Stack>

              <Stack direction="row" alignItems="center" gap={1} sx={{ paddingTop: '5px' }}>
                <Link
                  to="/support"
                  style={{
                    textDecoration: 'none',
                    gap: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <HelpCenterIcon
                    sx={{
                      fontSize: '1rem',
                      color: '#FFF'
                    }}
                  />
                  <Typography variant="caption" color="#FFF">
                    Support Form
                  </Typography>
                </Link>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ paddingBottom: '20px' }}>
            <Stack
              spacing={0.5}
              sx={{
                alignItems: {
                  sm: 'end'
                },
                lineHeight: '1.8'
              }}
            >
              <Typography
                variant="subtitle2"
                color={'primary.main'}
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: '600',
                  paddingBottom: '6px'
                }}
              >
                {' '}
                Menu
              </Typography>
              <Link
                to="/#about-us"
                style={{
                  textDecoration: 'none'
                }}
              >
                <Typography variant="caption" color="#FFF">
                  About Us
                </Typography>
              </Link>
              <Link
                to="/#projects"
                style={{
                  textDecoration: 'none'
                }}
              >
                <Typography variant="caption" color="#FFF">
                  Projects
                </Typography>
              </Link>

              <Link
                to="/impressum"
                style={{
                  textDecoration: 'none'
                }}
              >
                <Typography variant="caption" color="#FFF">
                  Impressum
                </Typography>
              </Link>
            </Stack>
          </Grid>
        </Grid>

        <Divider
          sx={{
            borderColor: '#fff',
            marginLeft: { md: '4.5rem', xs: '1.75rem' },
            marginRight: { md: '4.5rem', xs: '1.75rem' }
          }}
        />
        <Grid
          container
          sx={{
            paddingX: {
              md: '75px',
              xs: '30px'
            },
            paddingTop: '20px'
          }}
        >
          <Grid item xs={12} md={6} sx={{ paddingBottom: '20px' }}>
            <Stack>
              <Typography variant="caption" color="#FFF">
                Copyright @ 2024 retcor GmbH. All Rights Reserved.
              </Typography>
              <Typography variant="caption" color="#FFF">
                <PrivacyPolicy />
              </Typography>
              <Typography variant="caption" color="#FFF">
                <CookiePolicy />
              </Typography>
              <Typography variant="caption" color="#FFF">
                <TermsAndConditions />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingBottom: '20px' }}>
            <Stack
              sx={{
                alignItems: {
                  md: 'end'
                }
              }}
            >
              <Typography variant="caption" color="#FFF">
                Sie können uns auf Deutsch oder Englisch kontaktieren
              </Typography>
              <Typography variant="caption" color="#FFF">
                You could communicate in English or German .
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
