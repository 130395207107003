import React from 'react';
import { Box } from '@mui/material';

const SubProjectImage = ({ imageDetails }) => {
  return (
    <Box sx={{ background: '#E7E9EB', borderRadius: '15px', width: '100%', height: '100%' }} className="center">
      <Box
        className="center"
        sx={{
          padding: { md: '4rem', xs: '2rem' }
        }}
      >
        <a href={imageDetails[0].src} target="_blank" rel="noreferrer">
          <img src={imageDetails[0].src} alt={imageDetails[0].alt} className="responsive-image"></img>
        </a>
      </Box>
    </Box>
  );
};

export default SubProjectImage;
