import { ListItem, Typography, Box, Stack, List, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const SubProjectContent = ({ projectDetails }) => {
  return (
    <Box
      sx={{
        background: '#FAFBFC',
        borderRadius: '15px',
        width: '100%',
        height: '100%'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          padding: '2rem'
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h5" sx={{ paddingLeft: { lg: '1rem' }, fontWeight: '700' }}>
            {projectDetails.title}
          </Typography>
          <List>
            {projectDetails.content.map((item, index) => (
              <ListItem key={index} sx={{ paddingX: { xs: '0rem', lg: '1rem' } }}>
                <ListItemIcon sx={{ minWidth: '0', paddingRight: { md: '2rem', xs: '1rem' } }}>
                  <FiberManualRecordIcon color="primary" sx={{ fontSize: '0.8rem' }} />
                </ListItemIcon>
                <ListItemText disableTypography sx={{ fontSize: '14px !Important', lineHeight: '1.75' }}>
                  {item}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Box>
    </Box>
  );
};

export default SubProjectContent;
