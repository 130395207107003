import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useEffect } from 'react';

const Testimonials = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % 3);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1) % 3);
  };
  const interval = 5000;

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, interval);

    return () => clearInterval(timer);
  }, []);

  const content = [
    {
      label:
        'The consultant supported us in all phases of the project in the further development and error correction of the Order Management System. He quickly familiarized himself with the complex software architecture and development structure and fulfilled tasks very committedly - and where necessary, with his own ideas. We would like to express our fullest satisfaction hereby, and we hereby extend our explicit recommendation to other clients.'
    },
    {
      label:
        'We found him to be a committed, independent, and dependable developer. His work in software design and development is outstanding. Even when deadlines were tight, he remained organized and met milestones reliably. He consistently completed tasks to our utmost satisfaction and in line with our company standards. I highly recommend him for challenging projects and would gladly work with him again if needed.'
    },
    {
      label:
        'The freelance software developer quickly and seamlessly integrated into our Scrum team. He consistently completed the tasks assigned to him independently with great care and precision. He quickly familiarized himself with the assigned areas of responsibility (including new technical areas). We are happy to recommend him for his excellent qualifications and strong commitment. We would be glad to collaborate with him again if needed.'
    }
  ];

  return (
    <React.Fragment>
      <Box sx={{ paddingTop: { md: '2.5rem', xs: '1rem' } }}>
        <Stack spacing={2} sx={{ paddingBottom: '50px' }} className="center">
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              textAlign: 'center',
              fontSize: '1.5rem',
              fontWeight: '600'
            }}
          >
            TESTIMONIALS
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              fontWeight: '800',
              paddingX: {
                xs: '20px',
                md: '0px'
              }
            }}
          >
            What Our Clients Say About Us{' '}
          </Typography>
          <Box
            sx={{
              height: { md: '300px' },
              width: { md: '700px' },
              marginTop: '4rem !important'
            }}
          >
            <Box
              sx={{
                backgroundColor: '#fafbfc',
                borderRadius: '15px',
                padding: { md: '4rem', xs: '2rem' }
              }}
            >
              <FormatQuoteIcon fontSize="large" color="white" />
              <Typography
                paragraph
                sx={{
                  textAlign: 'justify',
                  fontSize: '15px',
                  lineHeight: '1.75'
                }}
              >
                {content[activeStep].label}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: { md: '2.5rem', xs: '1rem' }
        }}
      >
        <Box sx={{ width: '800px' }}>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            color="red"
            sx={{
              width: '100%',
              flexGrow: 1,
              //paddingX: { md: '12rem' },
              '&.MuiMobileStepper-root': {
                backgroundColor: 'transparent'
              }
            }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === 2}
                sx={{
                  color: (theme) => theme.palette.primary.main
                }}
              >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{
                  color: (theme) => theme.palette.primary.main
                }}
              >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              </Button>
            }
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Testimonials;
