import React from 'react';

import { MobileStepper } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@emotion/react';

const SubProjectStepper = ({ steps, imageDetails }) => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box
      sx={{
        background: '#E7E9EB',
        borderRadius: '15px',
        width: '100%',
        height: '100%'
      }}
      className="center"
    >
      <Box
        sx={{
          padding: { md: '4rem', xs: '2rem' }
        }}
      >
        <a href={imageDetails[activeStep].src} target="_blank" rel="noreferrer">
          <img src={imageDetails[activeStep].src} alt={imageDetails[activeStep].alt} className="responsive-image"></img>
        </a>
        <MobileStepper
          sx={{
            '&.MuiPaper-root': {
              background: 'transparent'
            }
          }}
          variant="dots"
          steps={steps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === steps - 1}
              sx={{
                color: 'primary.main'
              }}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{ color: 'primary.main' }}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default SubProjectStepper;
