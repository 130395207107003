import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import impressum from '../assets/impressum.png';
import impressumMobile from '../assets/impressum-mobile.png';
import { useMediaQuery, useTheme } from '@mui/material';

const Impressum = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          paddingY: '20px',
          marginTop: { md: '64px' }
        }}
      >
        <Typography variant="h3" sx={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: '600' }}>
          Impressum
        </Typography>
      </Box>
      {/* <Divider /> */}
      <Box
        sx={{
          paddingLeft: { xs: '40px', md: '80px' },
          paddingY: { xs: '20px', md: '60px' }
        }}
      >
        <img src={!isMediumScreen ? impressum : impressumMobile} alt="impressum"></img>
        <Box sx={{ paddingLeft: { md: '2px' } }}>
          <Stack spacing={0} sx={{ paddingBottom: '8px' }}>
            <Typography variant="subtitle2">PGP Fingerprint:</Typography>
            <Typography variant="body2">29E1 5E56 DD63 8492 64B0 5CCF D088 59AC 7A90 4763</Typography>
          </Stack>
          <Stack spacing={0} sx={{ paddingBottom: '8px' }}>
            <Typography variant="subtitle2">Registernummer:</Typography>
            <Typography variant="body2">HRB42880</Typography>
          </Stack>
          <Stack spacing={0} sx={{ paddingBottom: '8px' }}>
            <Typography variant="subtitle2">Registergericht:</Typography>
            <Typography variant="body2">Amtsgericht Bielefeld</Typography>
          </Stack>
          <Stack spacing={0} sx={{ paddingBottom: '8px' }}>
            <Typography variant="subtitle2">
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 263 897 228
            </Typography>
          </Stack>
          <Stack spacing={0} sx={{ paddingBottom: '8px' }}>
            <Typography variant="subtitle2"> Angaben gemäß § 5 TMG:</Typography>
            <Typography variant="body2">retcor® software and hardware engineering services</Typography>
            <Typography variant="body2">
              retcor&#xae; ist ein eingetragenes Markenzeichen. Siehe
              <a
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: '400'
                }}
                target="_blank"
                rel="noreferrer"
                href="https://register.dpma.de/DPMAregister/marke/register/3020152255952/DE"
              >
                {' '}
                DPMA
              </a>{' '}
              und
              <a
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: '400'
                }}
                target="_blank"
                rel="noreferrer"
                href="https://tsdr.uspto.gov/#caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch&caseNumber=79201683"
              >
                {' '}
                USPTO
              </a>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Impressum;
